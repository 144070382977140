import { GetBy } from "../../core/Element";

const radioValidator = (form, input) => {
    let valid = false;
    const siblings = GetBy.selector(`[name="${input.name}"]`, form);

    for (let i = 0; i < siblings.length; i++) {
        const element = siblings[i];
        if (element.checked) valid = true;
    }

    return valid;
};

export { radioValidator };
