import gsap from 'gsap';

import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { Interaction } from '../_app/cuchillo/core/Interaction';
import { isSmartphone } from '../_app/cuchillo/core/Basics';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { CSS } from '../_app/cuchillo/utils/CSS';

class ScrollItem__Footer extends VScroll_Item {
  eyesHolder;
  eyes;
  progress = { x: 0, y: 0 }
  tick = 0;
  tickLimit = 100;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.eyesHolder = GetBy.selector(".eyes", __link)[0];
    this.eyes = GetBy.selector(".eyes svg", __link)[1];

    this._call = () => {
      this.loop();
    }

    this.onShow = () => {
      gsap.ticker.add(this._call);
    };
    this.onHide = () => {
      gsap.ticker.remove(this._call);
    };
    this.onMove = () => { };
  }

  loop() {
    const box = this.eyesHolder.getBoundingClientRect();
    const position = {
      x: Interaction.positions.mouse.x - (box.left + box.width * .5),
      y: Interaction.positions.mouse.y - (box.top + box.height * .5),
    }
    const offset = box.width * .10;

    if (!isSmartphone) {
      this.progress = {
        x: Math.min(1, Maths.normalize(box.width * 5, 0, position.x)),
        y: Math.min(1, Maths.normalize(box.width * 3, 0, position.y)),
      }
    } else {
      this.tick++;

      if (this.tick >= this.tickLimit) {
        this.tick = 0;
        this.tickLimit = Maths.maxminRandom(20, 10) * 10;

        this.progress = {
          x: Maths.maxminRandom(2, -2) * .5,
          y: Maths.maxminRandom(2, -2) * .5
        }
      }
    }

    this.eyes.style.transform = CSS.translate3D(offset * this.progress.x, offset * this.progress.y, 1);
  }

  dispose() {
    super.dispose();
  }

  resize(__w, __h) {
    super.resize(__w, __h);
  }
}

Scroll._registerClass('Footer', ScrollItem__Footer);