import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { Maths } from '../_app/cuchillo/utils/Maths';

const CLASSES = ['--blue', '--white'];

class ScrollItem__BlockAbout extends VScroll_Item {
  strongs;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.strongs = GetBy.selector('strong:not([class])', __link);

    for (let i = 0; i < this.strongs.length; i++) {
      const tag = this.strongs[i];
      tag.classList.add('color-tag');

      const rand = Maths.maxminRandom(1, 0);
      tag.classList.add(CLASSES[rand]);
    }

    this.onShow = () => { };
    this.onHide = () => { };
    this.onMove = () => { };
  }

  dispose() {
    super.dispose();
  }

  resize(__w, __h) {
    super.resize(__w, __h);
  }
}

Scroll._registerClass('block-about', ScrollItem__BlockAbout);