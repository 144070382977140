export default class FormSender {
  static URL_SENDER = '/.netlify/functions/email-sender';
  static URL_UPLOADER = '/.netlify/functions/file-uploader';

  static formatHTML(__data) {
    let html = "";
    const keys = Object.keys(__data);
    keys.forEach((key, index) => {
      html = `${html}<b>${key}:</b> ${__data[key]}<br>`
    });

    return html;
  }

  static writeEmail(__data) {
    let texts = {
      'es': `¡Enhorabuena!
        <br>
        <br>
  
        <b>Te confirmamos la inscripción gratuita a la actividad %title%, en el marco del festival de arquitecturas OPEN 2024.</b>
        <br>
        <br>
  
        A continuación destacamos la información de la reserva realizada:      
        <br>
        <b>LUGAR:</b> %address% <br>
        <b>IDIOMA, DIA Y HORA:</b> %time% <br>
        <b>TITULAR DE LA RESERVA:</b> %name% <br>
        <b>NÚMERO DE PERSONAS TOTALES:</b> %people% <br>
        <b>DURACIÓN:</b> %minutes% min<br>
        <br>
        <br>

        <b>Por favor, lee con atención las siguientes indicaciones:</b>
        <br>
        <ul>
          <li><i><u>Es obligatorio personarse 15 minutos antes de actividad.</u> De no ser así, se perderá la plaza.</i></li>
          <li><i>Guarda este email con los datos de tu reserva y recuerda presentarlo en tu dispositivo digital o impreso
          a la hora de acceder a la actividad.</i></li>
          <li><i>En el caso de haber hecho más de una inscripción, se tomará como válida la primera reserva realizada.</i></li>
          <li><i>En el caso de no poder asistir, solicitamos que se notifique para poder liberar las plazas.</i></li>
        </ul>
  
        <br>
        <br>
        Para dudas relacionadas con esta actividad escríbenos a: <a href="mailto:actividades@openhousebilbao.org">actividades@openhousebilbao.org</a>.
        <br><br>
        Te deseamos sea una grata experiencia. No olvides compartirla en tus redes con el hashtag <b>#OPENBio2024.</b>
        <br>
        <br>
        ¡Un saludo!`,
      'eu': `
        Zorionak!
        <br>
        <br>
       
        <b>Zuzen jaso dugu OPEN 2024 jaialdiaren barnean dagoen %title% izeneko ekimenean doan parte hartzeko zure eskaria.</b>
       
        <br>
        <br>

        <b>Jarraian, zure erreserbaren inguruko informazioa laburbiltzen dizugu:</b>
        <br>
        <b>LEKUA:</b> %address% <br>
        <b>HIZKUNTZA, EGUNA ETA ORDUA:</b> %time% <br>
        <b>ERRESERBAREN JABEAREN IZEN-ABIZENAK:</b> %name% <br>
        <b>PERTSONA KOPURUA:</b> %people% <br>
        <b>IRAUPENA:</b> %minutes% min<br>
        <br>
        <br>

        Mesedez, arretaz irakurri itzazu ondorengo jarraibideak:
        <br>

        <ul>
          <li><i><u>Ekintza hasi baino 15 minutu lehenago bertaratu, mesedez.</u> Bestela, plaza galduko da.</i></li>
          <li><i>Zure erreserbaren datuak biltzen dituen email hau gorde ezazu eta gogoratu ezazu egunean bertan paperean edo digitalki aurkeztea.</i></li>
          <li><i>Izena behin baino gehiagotan eman baduzu, ontzat hartuko dugu egindako lehen erreserba.</i></li>
          <li><i>Etortzerik ez baduzu, jakinaraz iezaguzu, mesedez, lekuak libratu ahal izateko.</i></li>
        </ul>

        <br>
        <br>

        Ekintza honi buruzko zalantzak argitzeko, hurrengo helbidera idatzi: <a href="mailto:actividades@openhousebilbao.org">actividades@openhousebilbao.org</a>.
        <br><br>
        Azkenean ekintzan parte hartu ezin baduzu, antolakuntzarekin harremanetan jarri. Zure esperientzia atsegina izatea espero dugu. Ez ahaztu zure sare-sozialetan partekatzea <b>#OPENBio2024</b> traolarekin.
        
        <br>
        <br>

        Agur bat!
      `
    };

    const html = texts[__data.lang]
      .replace('%name%', __data.name)
      .replace('%time%', __data[__data.events])
      .replace('%title%', __data.project_name)
      .replace('%people%', __data.assistants)
      .replace('%address%', __data.address)
      .replace('%minutes%', __data.minutes_visit)

    return html;
  }

  static async sendEmail(__data, __replyto) {
    return new Promise((resolve, reject) => {
      const emailSender = new XMLHttpRequest();
      emailSender.open('POST', this.URL_SENDER);
      emailSender.onload = () => {
        if (emailSender.status === 200) {
          resolve(emailSender.status);
        } else {
          reject(emailSender.status);
        }
      };

      const formData = new FormData();
      for (var key in __data) {
        formData.append(key, __data[key]);
        console.log('Key', key, __data[key], formData);
      }

      if (__replyto) {
        formData.append("replyTo", __replyto);
      }

      console.log(__data, formData);

      emailSender.send(formData);
    });
  }

  static async sendFile(__file, opts = {}) {
    const formName = opts.formName ? opts.formName : 'Contact';

    return new Promise((resolve, reject) => {
      const fileSender = new XMLHttpRequest();
      fileSender.open('POST', this.URL_UPLOADER);
      fileSender.onload = () => {
        if (fileSender.status === 200) {
          resolve(JSON.parse(fileSender.response));
        } else {
          reject(fileSender.response);
        }
      };

      const formData = new FormData();
      formData.append('form-name', formName);
      formData.append(__file.name, __file.value);

      fileSender.send(formData);
    });
  }
}
