import { Functions } from '../_app/cuchillo/utils/Functions';
import { GetBy } from '../_app/cuchillo/core/Element';
import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { fromLonLat } from 'ol/proj';
import { Point } from 'ol/geom';
import MouseWheelZoom from 'ol/interaction/MouseWheelZoom'; // Asegúrate de importar la interacción de zoom con la rueda
import XYZ from 'ol/source/XYZ';


export class OLMaps {
  static _maps = {};
  static _coords = [];
  static svgMarker = `
    <svg viewBox="0 0 1080 1080" width="1080" height="1080"><use xlink:href="/assets/images/sprite.svg#logo-xs"></use></svg>
  `;

  static init() {
    this.getCoords();
    this.getMaps();
  }

  static getMaps() {
    [...GetBy.selector("[data-olmap]")].map(dom => {
      if (this._coords.length === 0) {
        dom.parentNode.remove();
        return;
      }

      const id = Functions.getId(dom);
      const olmap = new OLMap(dom, id, this._coords);
      this._maps[id] = olmap;
    });
  }

  static getCoords() {
    [...GetBy.selector('[data-coords]')].map(dom => {
      if (dom.dataset.lat && dom.dataset.lng) {
        this._coords.push({
          title: dom.dataset.title,
          lat: parseFloat(dom.dataset.lat),
          lng: parseFloat(dom.dataset.lng),
          href: dom.getAttribute('href')
        });
      }
    });
  }

  static dispose() {
    Object.entries(this._maps).map((item) => { item[1].dispose() });
    this._maps = {}
    this._coords = [];
  }
}

export default class OLMap {
  id;
  container;
  olmap;
  isEnabled = false;

  constructor(__dom, __id, coords) {
    this.container = __dom;
    this.id = __id;

    const center = coords[0] ?
      new Point([coords[0].lng, coords[0].lat]).transform('EPSG:4326', 'EPSG:3857') :
      new Point([-2.93499, 43.2630]).transform('EPSG:4326', 'EPSG:3857');

    this.olmap = new Map({
      target: this.id, // El ID del elemento donde se mostrará el mapa
      layers: [
        new TileLayer({
          source: new XYZ({
            url: 'https://{a-d}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
            attributions: '© OpenStreetMap contributors, © CARTO',
          }),
        }),
      ],
      view: new View({
        center: center.flatCoordinates, // Coordenadas iniciales del mapa (en proyección EPSG:3857)
        zoom: 14, // Nivel de zoom inicial
        minZoom: 14, // Establecer el zoom mínimo
        maxZoom: 30 // Establecer el zoom máximo
      })
    });

    // Actualiza la clase del contenedor según el nivel de zoom inicial
    this.updateZoomClass();

    // Desactivar el zoom con la rueda
    this.olmap.getInteractions().forEach((interaction) => {
      if (interaction instanceof MouseWheelZoom) {
        interaction.setActive(false); // Desactiva el zoom con la rueda
      }
    });

    // Añadir marcadores HTML al mapa después de que el mapa se haya renderizado
    this.olmap.on('postrender', () => {
      if (this.isEnabled) return;
      this.isEnabled = true;
      this.addHtmlMarkers(coords);
    });

    // Escuchar el evento de movimiento del mapa para actualizar zoom y marcadores
    this.olmap.on('moveend', () => {
      this.updateMarkers(coords); // Actualiza la posición de los marcadores
      this.updateZoomClass(); // Actualiza la clase del contenedor con el zoom
    });

    this.olmap.on('pointerdrag', () => {
      this.updateMarkers(coords); // Actualiza la posición de los marcadores
    });
  }

  addHtmlMarkers(coords) {
    this.markers = []; // Almacena referencias a los marcadores

    coords.forEach((coord, index) => {
      const coordsLonLat = fromLonLat([coord.lng, coord.lat]);
      const pixel = this.olmap.getPixelFromCoordinate(coordsLonLat);

      let marker;
      if (coord.href) {
        marker = document.createElement('a');
        marker.setAttribute("href", coord.href);
      } else {
        marker = document.createElement('div');
      }

      marker.className = 'marker';

      // Insertar el SVG como contenido del marcador
      marker.innerHTML = OLMaps.svgMarker;

      // Añadir un título o número dentro del marcador
      const span = document.createElement('span');
      span.innerText = coord.title;
      marker.appendChild(span);

      // Añadir el marcador al contenedor del mapa
      this.container.appendChild(marker);
      this.markers.push(marker); // Guardar referencia del marcador

      if (pixel) {
        // Posicionar el marcador en el mapa
        marker.style.position = "absolute";
        marker.style.left = `${pixel[0] - 15}px`; // Centrar el marcador
        marker.style.top = `${pixel[1] - 15}px`;  // Centrar el marcador
      }
    });
  }

  updateMarkers(coords) {
    coords.forEach((coord, index) => {
      const coordsLonLat = fromLonLat([coord.lng, coord.lat]);
      const pixel = this.olmap.getPixelFromCoordinate(coordsLonLat);
      const marker = this.markers[index]; // Obtener el marcador correspondiente

      if (marker && pixel) {
        // Actualizar la posición del marcador
        marker.style.left = `${pixel[0] - 15}px`; // Centrar el marcador
        marker.style.top = `${pixel[1] - 15}px`; // Centrar el marcador
      }
    });
  }

  updateZoomClass() {
    const zooms = {}
    zooms[14] = .5;
    zooms[15] = .65;
    zooms[16] = .8;
    zooms[17] = .95;
    zooms[18] = 1.05;
    zooms[19] = 1.20;
    zooms[20] = 1.35;
    zooms[21] = 1.50;
    // Obtener el nivel de zoom actual
    const zoom = Math.max(14, Math.min(20, Math.round(this.olmap.getView().getZoom())));

    // Eliminar cualquier clase de zoom previa
    this.container.classList.forEach(cls => {
      if (cls.startsWith('--zoom-')) {
        this.container.classList.remove(cls);
      }
    });

    // Añadir la nueva clase de zoom
    this.container.classList.add(`--zoom-${zoom}`);
    this.container.style.setProperty('--zoom', zooms[zoom]);
  }

  dispose() {
    this.olmap.setTarget(null); // Limpiar el mapa
    this.container.innerHTML = ''; // Limpiar los marcadores
  }
}
