import { GetBy } from "../../core/Element";

const slotValidator = (form, input, radioName) => {
    if (input.value.trim() === '' || parseInt(input.value) === NaN) return false;

    let valid = false;
    const radios = GetBy.selector(`[name="${radioName}"]`, form);

    for (let i = 0; i < radios.length; i++) {
        const element = radios[i];
        if (element.checked) {
            const requested = parseInt(input.value);
            const left = parseInt(element.dataset.slots);

            if (requested <= 4 && requested <= left) valid = true;
        }
    }

    if (!valid) input.classList.add('--slot-error');
    else input.classList.remove('--slot-error');

    return valid;
};

export { slotValidator };
