import { gsap } from 'gsap';
import { C, GetBy } from '../core/Element';
import { isTouch, isReducedMotion, Basics } from '../core/Basics';
import { Functions } from '../utils/Functions';
import Scrollbar from '../scroll/Scrollbar';

export class Videos {
  static _videos = {};
  static _observer = null;
  static _options = {
    rootMargin: '0px 0px 100px 0px',
    threshold: 0
  };

  static init() {
    this.setupObserver();
    this.getVideos();
  };

  static getVideos() {
    [...GetBy.selector("[data-video]")].map(dom => {
      const id = Functions.getId(dom);
      const video = new Video(dom);
      this._videos[id] = video;

      if (!isReducedMotion) {
        this._observer.observe(dom);
      }
    });
  };

  static setupObserver() {
    if (this._observer) {
      this._observer.disconnect();
    }

    if (!isReducedMotion) {
      this._observer = new IntersectionObserver((entries) => { this.checkObserver(entries) }, this._options);
    }
  }

  static checkObserver(entries, observer) {
    entries.forEach(entry => {
      const id = Functions.getId(entry.target);
      const video = this._videos[id];

      if (entry.isIntersecting) {
        if (video.isReady) {
          if ((video.options.autoplay && !video.options.useractions.pause) || video.options.useractions.play) {
            video.play();
          }
        } else {
          video.load();
        }
      } else if (video._video.currentTime > 0) {
        video.pause();
      }
    });
  }

  // Método para actualizar el src del video y añadirlo al observer
  static updateVideoSrc(id, src) {
    console.log('UPDATE VIDEO', id, src);

    const video = this._videos[id];

    console.log('Video', video);

    if (video) {
      video.setSource(src);

      this.refresh();
      // if (!isReducedMotion) {
      //   this._observer.observe(video._video); // Añadir el video al observer
      // }
    }
  }

  static refresh() {
    if (this._observer) {
      // Stop observing all current items
      Object.values(this._videos).forEach(video => {
        this._observer.unobserve(video._video);
      });
      // Observe them again
      Object.values(this._videos).forEach(video => {
        this._observer.observe(video._video);
      });
    }
  }

  static dispose() {
    if (this._observer) { this._observer.disconnect(); }

    Object.entries(this._videos).map((item) => { item[1].dispose() });

    this._videos = {}
    this._observer = null;
  }
};

export default class Video {
  container;
  isShow = false;
  isReady = false;
  options = {
    autoplay: false,
    loop: false,
    interfaceHideTime: 5000,
    useractions: {
      muted: false,
      play: false,
      pause: false
    }
  }

  _video;
  _timer;
  _title;
  _progress;
  _wheel;
  _idTimerInterface;

  _calls = {
    loop: () => { this.loop(); },
    move: () => { this.showInterface(); },
    play: (e) => {
      this.play();
      this._styles.play();

      if (this._progress || this._timer) gsap.ticker.add(this._calls.loop);
    },
    pause: (e) => {
      this.pause();
      this._styles.pause();

      if (this._progress || this._timer) gsap.ticker.remove(this._calls.loop);
    },
    mute: (e) => { this.mute(); },
    click: (e) => { this._click(e); },
    move: (e) => { this.showInterface(e); },
    fullscreen: (e) => {
      setTimeout(() => {
        if (this._video.muted) this.mute();
        this.play();
      }, 500);
    },
    canplay: (e) => {
      console.log('CAN PLAY');
      this.isReady = true;
      this.loaded();
    }
  }

  _styles = {
    play: () => {
      this.container.classList.add("--play");
      this.container.classList.remove("--pause")
    },
    pause: () => {
      this.container.classList.add("--pause");
      this.container.classList.remove("--play")
    },
    mute: () => {
      this.container.classList.add("--muted");
      this.container.classList.remove("--unmuted")
    },
    unmute: () => {
      this.container.classList.add("--unmuted");
      this.container.classList.remove("--muted")
    },
    hideInterface: () => {
      this.container.classList.add("--hide-interface");
      this.container.classList.remove("--show-interface");
    },
    showInterface: () => {
      this.container.classList.add("--show-interface");
      this.container.classList.remove("--hide-interface");
    },
    loaded: () => {
      this.container.classList.add("--loaded");
      this.container.classList.remove("--loading");
    }
  }

  constructor(__video) {
    this.container = __video.parentNode;
    this._video = __video;
    this._title = GetBy.selector("[data-title]", this.container)[0];
    this._timer = GetBy.selector("[data-timer]", this.container)[0];
    this.options.autoplay = this._video.getAttribute("data-autoplay") != null;

    this._setupProgress();
    this._setupEvents();
    this._setupControls();
  }

  _setupEvents() {
    this._video.addEventListener("play", this._calls.play);
    this._video.addEventListener("pause", this._calls.pause);
    this._video.addEventListener("webkitendfullscreen", this._calls.fullscreen);
    this._video.addEventListener("canplaythrough", this._calls.canplay);
  }

  _setupControls() {
    this.container.addEventListener(Basics.clickEvent, this._calls.click);
    this.container.addEventListener(Basics.moveEvent, this._calls.move);
  }

  _setupProgress() {
    const domProgress = GetBy.selector("[data-progress]", this.container)[0];
    if (!domProgress) return;

    this._progress = new Scrollbar(domProgress);

    setTimeout(() => { if (this._progress) this._progress.resize(); }, 100);
    this._progress.onChange = (__progress) => {
      this._video.currentTime = this._video.duration * __progress;
      this._calls.loop();
    };
  }

  _click(e) {
    switch (e.target.tagName.toLowerCase()) {
      case "button":
        if (e.target.getAttribute("data-play") !== null) {
          this.options.useractions.play = true;
          this.options.useractions.pause = false;
          this.play();
        }
        if (e.target.getAttribute("data-pause") !== null) {
          this.options.useractions.play = false;
          this.options.useractions.pause = true;
          this.pause();
        }
        if (e.target.getAttribute("data-mute") !== null) {
          this.options.useractions.mute = true;
          this.mute();
        }
        if (e.target.getAttribute("data-fullscreen") !== null) { this.fullscreen(); }
        if (e.target.getAttribute("data-seek") !== null) {
          this._video.currentTime = Number(e.target.getAttribute("data-seek"));
          this.play();
        }
        break;
    }
  }

  setSource(src) {
    this._video.src = src;
    this.isReady = true; // Marcar como listo cuando se carga la fuente
    // this.loaded(); // Llamar a la función de carga

    this.load();
  }

  load() {
    if (!this.options.autoplay) return;

    var playPromise = this.preplay();

    if (playPromise !== undefined) {
      playPromise.then(_ => { })
        .catch(error => {
          document.addEventListener(Basics.clickEvent, this._calls.mute, { once: true });

          this.mute(true);
          this.showInterface(true);

          setTimeout(() => {
            this.play();
          }, 100);
        });
    }
  }

  loaded() {
    this._styles.loaded();
  }

  mute(__isMuted = !this._video.muted) {
    if (__isMuted) this._styles.mute();
    else this._styles.unmute();

    this._video.muted = __isMuted;
  }

  preplay() {
    return this._video.play();
  }

  play() {
    console.log('Video play');

    this._video.play()
      .then()
      .catch(error => this._styles.pause);
  }

  pause() {
    this._video.pause();
  }

  stop() {
    this.pause();
  }

  fullscreen() {
    setTimeout(() => {
      if (this._video.muted && !this.options.useractions.mute) this.mute();
    }, 100);
    this._video.webkitEnterFullscreen();
  }

  showInterface(__force = false) {
    this._styles.showInterface();

    if (__force) {
      clearTimeout(this._idTimerInterface);
      this._idTimerInterface = setTimeout(() => { this._styles.hideInterface(); }, this.options.interfaceHideTime);
    }
  }

  changeTime(__time) {
    this.showInterface();

    if (__time > this._video.duration) {
      __time = __time - this._video.duration;
    } else if (__time < 0) {
      __time = this._video.duration - __time;
    }

    this._video.currentTime = __time;
  }

  loop() {
    if (this.isReady) {
      const timeRendered = this.secondsToPlayerTime(this._video.duration - this._video.currentTime);

      if (this._timer) {
        this._timer.textContent = timeRendered;
      }

      if (this._progress) {
        this._progress.update(this._video.currentTime / this._video.duration)
      }
    }
  }

  secondsToPlayerTime(__sec) {
    const MINS = Math.floor(__sec / 60);
    const SECS = Math.floor(__sec - MINS * 60);
    const CENTS = Math.floor((__sec - SECS - MINS * 60) * 100);

    const sMINS = MINS < 10 ? "0" + MINS : MINS.toString();
    const sSECS = SECS < 10 ? "0" + SECS : SECS.toString();
    const sCENTS = CENTS < 10 ? "0" + CENTS : CENTS.toString();

    return sMINS + ":" + sSECS + ":" + sCENTS
  }

  show() { }

  hide() { }

  dispose() {
    clearTimeout(this._idTimerInterface);

    this.stop();

    document.removeEventListener(Basics.clickEvent, this._calls.mute);

    this.container.removeEventListener(Basics.clickEvent, this._calls.click);
    this.container.removeEventListener(Basics.moveEvent, this._calls.move);
    this._video.removeEventListener("play", this._calls.play);
    this._video.removeEventListener("pause", this._calls.pause);
    this._video.removeEventListener("webkitendfullscreen", this._calls.fullscreen);
    this._video.removeEventListener("canplaythrough", this._calls.canplay);

    if (this._wheel) this._wheel.dispose();
    if (this._progress) this._progress.dispose();

    this.container = null;
    this.isShow = null;
    this.isReady = null;
    this.options = null;
    this._video = null;
    this._timer = null;
    this._title = null;
    this._progress = null;
    this._wheel = null;
    this._calls = null;
    this._styles = null;
    this._idTimerInterface = null;
  }
}


