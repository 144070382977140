import gsap, { Power2 } from 'gsap';

import { Basics, isSmartphone } from '../_app/cuchillo/core/Basics';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import { GetBy } from '../_app/cuchillo/core/Element';
import Default from './Default';
import Main from '../main';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';

export default class Projects extends Default {
  filterButton;
  filterPanels;
  filterWrapper;
  filtersOpen;
  filters;
  toggles;
  cards;
  footer;
  filterBanner;
  filtersShown = true;

  listContainer;
  toggleViewBtns;

  _open = false;

  _calls = {
    click: e => {
      const btn = e.target;
      const siblings = GetBy.class('__filter', btn.parentNode);

      // Selects current filter
      for (let i = 0; i < siblings.length; i++) {
        siblings[i].classList.remove('--active');
      }

      btn.classList.add('--active');

      this._calls.filter();
    },
    toggle: e => {
      const btn = e.target;

      if (btn.classList.contains('--selected')) {
        btn.classList.remove('--selected');
      } else {
        btn.classList.add('--selected');
      }

      this._calls.filter();
    },
    filter: () => {
      // Gets active filters
      const categs1 = this.toggles.filter(toggle => toggle.classList.contains('--selected') && toggle.dataset.categ.indexOf('all') < 0).map(toggle => `__categ-${toggle.dataset.categ}`);
      const categs2 = this.filters.filter(filter => filter.classList.contains('--active') && filter.dataset.categ.indexOf('all') < 0).map(filter => `__categ-${filter.dataset.categ}`);
      const categs = [...categs1, ...categs2];

      console.log(categs);

      // Clears cards
      for (let i = 0; i < this.cards.length; i++) {
        this.cards[i].classList.remove('--hidden');
      }

      // Gets cards with all categs active
      for (let i = 0; i < this.cards.length; i++) {
        const card = this.cards[i];
        let active = true;

        for (let j = 0; j < categs.length; j++) {
          const categ = categs[j];
          if (!card.classList.contains(categ)) active = false;
        }

        if (!active) this.cards[i].classList.add('--hidden');
      }

      // Main.resize();
      Scroll.resize();
      Scroll.goto(0);
    },
    toggleFilters: () => {
      if (this._open) {
        this.hideFilters();
      } else {
        this.showFilters();
      }

      this._open = !this._open;
    },
    toggleview: (e) => {
      const target = e.target;

      for (let i = 0; i < this.toggleViewBtns.length; i++) {
        const btn = this.toggleViewBtns[i]
        if (target === btn) {
          btn.classList.add('--active');
          this.listContainer.classList.add(btn.dataset.type);
        } else {
          btn.classList.remove('--active');
          this.listContainer.classList.remove(btn.dataset.type);
        }
      }

      Scroll.resize();
      Scroll.goto(0);
    }
  }

  constructor() {
    super();
    this.footer = GetBy.id('Footer');
    this.filterBanner = GetBy.id('FilterBanner');

    this.filterButton = GetBy.id('filters-toggle');
    this.filterButton.addEventListener(Basics.clickEvent, this._calls.toggleFilters);

    this.filterWrapper = GetBy.selector('.filters .buttons', this.container)[0];
    if (isSmartphone) gsap.set(this.filterWrapper, { y: '100%', opacity: 0 });

    this.filterPanels = GetBy.selector('.filters .panel', this.container);
    if (!isSmartphone) gsap.set(this.filterPanels, { y: '100%' });

    this.cards = [...GetBy.class('__card', this.container)];

    this.filters = [...GetBy.class('__filter', this.container)];
    for (let i = 0; i < this.filters.length; i++) {
      this.filters[i].addEventListener(Basics.clickEvent, this._calls.click);
    }

    this.toggles = [...GetBy.class('__toggle', this.container)];
    for (let i = 0; i < this.toggles.length; i++) {
      this.toggles[i].addEventListener(Basics.clickEvent, this._calls.toggle);
    }

    this.listContainer = GetBy.id('ProjectList');
    this.toggleViewBtns = [...GetBy.class('__toggle-view', this.container)];
    for (let i = 0; i < this.toggleViewBtns.length; i++) {
      this.toggleViewBtns[i].addEventListener(Basics.clickEvent, this._calls.toggleview);
    }
  }

  showFilters() {
    if (isSmartphone) {
      gsap.to(this.filterWrapper, { y: 0, ease: Power2.easeOut, duration: .3 });
      gsap.to(this.filterWrapper, { opacity: 1, ease: Power2.easeOut, duration: .2, delay: .1 });
    } else {
      for (let i = 0; i < this.filterPanels.length; i++) {
        gsap.to(this.filterPanels[i], { y: 0, delay: i * .05, duration: this.filterPanels.length * .1 - .05 * i, ease: Power2.easeOut });
      }
    }
  }

  hideFilters() {
    if (isSmartphone) {
      gsap.to(this.filterWrapper, { opacity: 0, ease: Power2.easeOut, duration: .2 });
      gsap.to(this.filterWrapper, { y: '100%', ease: Power2.easeOut, duration: .1, delay: .1 });
    } else {
      for (let i = 0; i < this.filterPanels.length; i++) {
        gsap.to(this.filterPanels[i], { y: '100%', duration: .15 + .05 * i });
      }
    }
  }

  beforeHide() {
    super.beforeHide();

    for (let i = 0; i < this.filters.length; i++) {
      this.filters[i].removeEventListener(Basics.clickEvent, this._calls.click);
    }

    for (let i = 0; i < this.toggles.length; i++) {
      this.toggles[i].removeEventListener(Basics.clickEvent, this._calls.toggle);
    }

    for (let i = 0; i < this.toggleViewBtns.length; i++) {
      this.toggleViewBtns[i].removeEventListener(Basics.clickEvent, this._calls.toggleview);
    }
  }

  loop() {
    super.loop();

    const { top } = this.footer.getBoundingClientRect();
    const p = top - window.innerHeight;

    if (p < 0 && this.filtersShown) {
      gsap.to(this.filterBanner, { opacity: 0, duration: .2 });
      this.filterBanner.style.pointerEvents = 'none';
      this.filterBanner.style.visibility = 'hidden';
      this.filtersShown = false;
    } else if (p > 0 && !this.filtersShown) {
      this.filterBanner.style.pointerEvents = 'initial';
      this.filterBanner.style.visibility = 'visible';
      gsap.to(this.filterBanner, { opacity: 1, duration: .3 });
      this.filtersShown = true;
    }
  }
}

ControllerPage._addPage('projects', Projects);
