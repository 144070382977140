import gsap, { Power2 } from 'gsap';

import { GetBy } from '../_app/cuchillo/core/Element';
import _Sidemenu from '../_app/cuchillo/layout/Sidemenu';
import EventDispatcher from '../_app/cuchillo/core/EventDispatcher';
import { isMobile } from '../_app/cuchillo/core/Basics';

export default class Sidemenu extends _Sidemenu {
  static nav = GetBy.selector('nav', this.container)[0];
  static logo = GetBy.class('logo', this.container)[0];
  static overlay = GetBy.id('MenuOverlay');

  static show(__d = 0) {
    this.nav.style.display = 'initial';
    this.overlay.style.display = 'initial';
    this.nav.setAttribute('aria-expanded', 'true');
    this.state = Sidemenu.STATE_OPEN;
    this.show__effect();
  }

  // SHOW
  static show__effect(__d = 0) {
    let d = __d;

    this.logo.classList.remove('--closed');

    d += .2;
    gsap.to(this.overlay, {
      alpha: 1,
      delay: d,
      duration: .2,
      ease: Power2.easeOut
    });
    gsap.to(this.nav, {
      alpha: 1,
      delay: d,
      duration: .2,
      ease: Power2.easeOut,
      onComplete: () => {
        this.afterShow();
      }
    });
  }

  static hide() {
    this.disableScroll();
    this.state = Sidemenu.STATE_CLOSE;
    this.hide__effect();
  }

  static hide__effect() {
    this.logo.classList.add('--closed');

    gsap.to(this.overlay, {
      alpha: 0,
      duration: .1,
      ease: Power2.easeOut
    });
    gsap.to(this.nav, {
      alpha: 0,
      duration: .1,
      ease: Power2.easeOut,
      onComplete: () => {
        this.afterHide();
      }
    });
  }

  static directHide() {
    if (!isMobile) return;

    this.disableScroll();
    gsap.set(this.nav, { alpha: 0 });
    gsap.set(this.overlay, { alpha: 0 });
    this.logo.classList.add('--closed');
    this._state = Sidemenu.STATE_CLOSE;
    this.afterHide();
  }

  static afterHide() {
    this.overlay.style.display = 'none';
    this.nav.style.display = 'none';
    this.nav.setAttribute('aria-expanded', 'false');
    EventDispatcher.dispatchEvent(Sidemenu.ON_HIDE_END);
  }
}
