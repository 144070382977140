import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import { GetBy } from '../_app/cuchillo/core/Element';
import { FormValidatorSlots } from '../_app/cuchillo/forms/FormValidatorSlots';
import Default from './Default';
import { Functions } from '../_app/cuchillo/utils/Functions';

export default class Building extends Default {
  formValidator;
  mssg_ok;
  mssg_ko;

  _calls = {
    cb: success => this.callback(success)
  }

  constructor() {
    super();

    const form = GetBy.class('__form', this.container)[0];

    if (form) {
      this.mssg_ok = form.dataset.mssgOk;
      this.mssg_ko = form.dataset.mssgKo;

      this.formValidator = new FormValidatorSlots(form, this._calls.cb);
    }
  }

  //SHOW
  beforeShow() {
    super.beforeShow();

    if (!this.formValidator) return;

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    fetch(`/api/get-project?projId=${this.container.dataset.id}&postType=${this.container.dataset.postType}`)
      .then(response => response.json())
      .then(project => {
        if (project.error) {
          console.error('Error fetching project:', project.error);
          return;
        }

        project.acf.events.map(event => {
          const radio = GetBy.id('custom-radio-event-' + Functions.slugify(event.name))
          const input = GetBy.selector('input', radio)[0];
          const number = GetBy.class('number', radio)[0];

          let assistants = 0;
          if (event.assistants) assistants = event.assistants.length;
          const slots = parseInt(event.slots);
          const left = slots - assistants;

          if (left === 0) input.setAttribute('disabled', 'disabled');
          // input.value = left;
          input.dataset.slots = left;
          number.innerHTML = left;
        });
      })
      .catch(error => {
        console.error('Error:', error);
      });

    if (urlParams.get('success') !== null) {
      if (urlParams.get('success') === 'true') {
        alert(this.mssg_ok);
        this.triggerDeploy();
      } else {
        alert(this.mssg_ko);
      }

      urlParams.delete('success');
    }
  }

  callback(success = true) {
    let param = 'success';
    if (success) param += '=true';
    else param += '=false';

    let url = window.location.href;
    if (url.indexOf('?') > -1) url += '&' + param;
    else url += '?' + param;

    window.location.href = url;
  }

  triggerDeploy() {
    fetch('/.netlify/functions/trigger-deploy', { method: 'POST' })
      .then(response => response.json())
      .then(data => {
        // window.history.replaceState({}, document.title, '/');
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  afterHide() {
    if (this.formValidator) this.formValidator.dispose();
    super.afterHide();
  }
}

ControllerPage._addPage('building', Building);
