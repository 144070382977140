import { Videos } from '../_app/cuchillo/components/Videos';
import { Basics, isTouch } from '../_app/cuchillo/core/Basics';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Page from '../_app/cuchillo/pages/Page';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import Preloader from '../layout/Preloader';
import Wrap from '../layout/Wrap';
import Keyboard from '../_app/cuchillo/core/Keyboard';
import Cmp from '../windows/Cmp';
import { GetBy } from '../_app/cuchillo/core/Element';
import { OLMaps } from '../components/OLMaps';

export default class Default extends Page {
  constructor() {
    super();

    Videos.init();
    OLMaps.init();

    [...GetBy.selector('[data-toggle-cmp]')].map(item => {
      item.addEventListener(Basics.clickEvent, Cmp._calls.click);
    });
  }

  //SHOW
  beforeShow() {
    super.beforeShow();
    Scroll.init(Scroll.AXIS_Y, { domResize: this.container, smooth: !isTouch, multiplicator: 1 });
    Keyboard.mountPage(this.id);
  }

  show__effect(__call) {
    if (this.isFirstTime) {
      Wrap.show(() => {
        Preloader.hide(() => { this.afterShow() });
      });
    } else {
      Wrap.show(() => { this.afterShow() });
    }
  }

  afterShow() {
    super.afterShow();
    Scroll.start();
    Scroll.show();
  }

  //HIDE
  beforeHide() {
    Keyboard.unmountPage(this.id);
    super.beforeHide();
  }

  hide__effect() {
    Scroll.hide();
    Wrap.hide(() => { this.afterHide(); });
  }

  afterHide() {
    Videos.dispose();
    OLMaps.dispose();
    Scroll.dispose();

    [...GetBy.selector('[data-toggle-cmp]')].map(item => {
      item.removeEventListener(Basics.clickEvent, Cmp._calls.click);
    });

    super.afterHide();
  }

  //RESIZE
  resize() {
    super.resize();
  }

  //LOOP
  loop() {
    super.loop();
  }
}

ControllerPage._addPage('default', Default);
