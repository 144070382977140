import gsap from 'gsap';
import { GetBy, C } from '../core/Element';
import { ControllerPage } from '../pages/ControllerPage';
import { Functions } from '../utils/Functions';
import FormSender from './FormSender';
import { FormValidator } from './FormValidator';

export class FormValidatorSlots extends FormValidator {
  constructor(__form, __callback) {
    super(__form, __callback);
  }

  async send() {
    this._form.classList.add('--sending');

    const { events, assistants, email, project_id, project_name, project_post_type, name } = this._dataSend;
    const eventName = Functions.slugify(this._dataSend.events);
    let left = 0;
    let project;
    const assistantsList = [];
    for (let i = 0; i < assistants; i++) {
      assistantsList.push({ email, name });
    }

    await fetch(`/api/get-project?projId=${project_id}&postType=${project_post_type}`)
      .then(response => response.json())
      .then(proj => {
        if (proj.error) {
          console.error('Error fetching project:', proj.error);
          return;
        }

        project = proj;

        proj.acf.events.map(event => {
          if (Functions.slugify(event.name) === eventName) {
            let assistants = 0;

            if (event.assistants) {
              assistants = event.assistants.length;
              event.assistants = [...event.assistants, ...assistantsList];
            } else {
              event.assistants = assistantsList;
            }

            const slots = parseInt(event.slots);

            left = slots - assistants;
          }
        });
      })
      .catch(error => {
        console.error('Error:', error);
      });

    if (left < assistants) {
      this.callback(false);
      return;
    }

    let sendEmail = false;
    await fetch('/.netlify/functions/push-project', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({ id: project_id, project, project_post_type })
    })
      .then(response => response.json())
      .then(() => {
        this.callback();
        sendEmail = true;
      })
      .catch(error => {
        console.error('Error:', error);
      });

    if (sendEmail) {
      const formDataInternal = {
        project_name: this._dataSend.project_name,
        name: this._dataSend.name,
        email: this._dataSend.email,
        events: this._dataSend.events,
        assistants: this._dataSend.assistants,
      }
      const dataInternal = { html: FormSender.formatHTML(formDataInternal) };
      dataInternal.to = this._form.getAttribute('data-to');
      dataInternal.subject = this._form.getAttribute('data-subject');

      FormSender.sendEmail(dataInternal, this._form.getAttribute('data-from'))
        .then(status => {
          console.log('Email internal sent', status);
        })
        .catch(status => {
          console.log('Error', status);
        });


      // gsap.delayedCall(2, () => {
      const dataUser = { html: FormSender.writeEmail(this._dataSend) };
      dataUser.to = this._dataSend.email;
      dataUser.subject = this._form.getAttribute('data-subject');

      FormSender.sendEmail(dataUser, this._form.getAttribute('data-from'))
        .then(status => {
          console.log('Email user sent', status);
        })
        .catch(status => {
          console.log('Error', status);
        });
      // })
    }
  }
}
