import gsap from 'gsap';

import { SliderScroll } from '../_app/cuchillo/components/SliderScroll';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { GetBy } from '../_app/cuchillo/core/Element';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { Interaction } from '../_app/cuchillo/core/Interaction';
import { isSmartphone } from '../_app/cuchillo/core/Basics';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { CSS } from '../_app/cuchillo/utils/CSS';

const CLASSES = ['--pink', '--white', '--blue'];

class ScrollItem__BuildingBillboard extends VScroll_Item {
  eyesHolder;
  eyes;
  progress = { x: 0, y: 0 }
  tick = 0;
  tickLimit = 100;
  strongs = [];
  _slider;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.eyesHolder = GetBy.selector('.eyes', __link)[0];
    this.eyes = GetBy.selector('.eyes svg', __link)[1];

    this.strongs = GetBy.selector('strong:not([class])', __link);
    for (let i = 0; i < this.strongs.length; i++) {
      const tag = this.strongs[i];
      tag.classList.add('color-tag');

      const rand = i % CLASSES.length;
      tag.classList.add(CLASSES[rand]);
    }

    const isSlider = GetBy.class('__slider', __link).length > 0;
    if (!isSmartphone && isSlider) {
      this._slider = new SliderScroll(__link, {
        interaction: true,
        hasScrollbar: false,
        itemClass: ScrollItem__SliderImages__Item
      });
    }

    this._call = () => this.loop();

    this.onShow = () => {
      gsap.ticker.add(this._call);
    };
    this.onHide = () => {
      gsap.ticker.remove(this._call);
    };
    this.onMove = () => { };
  }

  loop() {
    const box = this.eyesHolder.getBoundingClientRect();
    const position = {
      x: Interaction.positions.mouse.x - (box.left + box.width * .5),
      y: Interaction.positions.mouse.y - (box.top + box.height * .5),
    }
    const offset = box.width * .10;

    if (!isSmartphone) {
      this.progress = {
        x: Math.min(1, Maths.normalize(box.width * 5, 0, position.x)),
        y: Math.min(1, Maths.normalize(box.width * 3, 0, position.y)),
      }
    } else {
      this.tick++;

      if (this.tick >= this.tickLimit) {
        this.tick = 0;
        this.tickLimit = Maths.maxminRandom(20, 10) * 10;

        this.progress = {
          x: Maths.maxminRandom(2, -2) * .5,
          y: Maths.maxminRandom(2, -2) * .5
        }
      }
    }

    this.eyes.style.transform = CSS.translate3D(offset * this.progress.x, offset * this.progress.y, 1);

    if (this._slider) this._slider.loop();
  }

  resize(w, h) {
    super.resize(w, h);
    if (this._slider) this._slider.resize();
  }

  dispose() {
    if (this._slider) this._slider.dispose();
    super.dispose();
  }
}

Scroll._registerClass('building-billboard', ScrollItem__BuildingBillboard);

class ScrollItem__SliderImages__Item extends VScroll_Item {
  hasHiddenEnabled = false;
  info;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================

  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================

  //update () {
  /* if (this.progress > .5) {
     const p = Math.max(0, Maths.normalize(1, .5, this.progress));
     this._x = this._x + ((Metrics.WIDTH * .5) * p);
   }*/
  /* super.update();
}

mouseOver () { }
mouseDown () { }
mouseUp () { }

show () {
  super.show();
}

hide () {
  super.hide();
}*/
}
