import { gsap } from "gsap";

import { Scroll } from './_app/cuchillo/scroll/Scroll';

import { Accessibility } from './_app/cuchillo/core/Accessibility';
import { Basics, isDebug, isSmartphone, isTouch } from './_app/cuchillo/core/Basics';
import { Interaction } from './_app/cuchillo/core/Interaction';
import { GetBy } from "./_app/cuchillo/core/Element";
import { Metrics } from './_app/cuchillo/core/Metrics';
import { Statics } from './_app/cuchillo/utils/Statics';
import Keyboard from './_app/cuchillo/core/Keyboard';

import LoaderController from './_app/cuchillo/loaders/LoaderController';
import PagesLoader from './_app/cuchillo/loaders/PagesLoader';
import MediaLoader from './_app/cuchillo/loaders/MediaLoader';

import VDisplace from "./_app/cuchillo/scroll/insiders/VDisplace";

import { ScrollItem__Footer } from './scroll/ScrollItem__Footer';
import { ScrollItem__BlockAbout } from './scroll/ScrollItem__BlockAbout';
import { ScrollItem__BuildingBillboard } from './scroll/ScrollItem__BuildingBillboard';

import { ControllerPage } from './_app/cuchillo/pages/ControllerPage';
import Page from './_app/cuchillo/pages/Page';
import Default from './pages/Default';
import Projects from './pages/Projects';
import Building from './pages/Building';
import Legal from './pages/Legal';

import BG from './_app/cuchillo/layout/Background';
import Header from './_app/cuchillo/layout/Header';
import Preloader from "./layout/Preloader";
import Sidemenu from "./layout/Sidemenu";
import Wrap from './layout/Wrap';
import Language from "./_app/cuchillo/utils/Language";
import CMP from "./windows/Cmp";
import EventDispatcher from "./_app/cuchillo/core/EventDispatcher";

export default class Main {
  static stats;

  static init() {
    gsap.ticker.fps(60);

    Sidemenu

    Basics.id = "open_v002"; // ID para cookies  
    Language.init();

    Sidemenu.init();
    Metrics.init(() => Main.resize()); // Tamaños y resize
    Keyboard.enable(); // ESC para cerrar ventana
    Accessibility.init(); // Utils accesibilidad
    Statics.init(); // Si estamos en debug pinta un FPS counter
    Interaction.init({ ajax: true }) // Posiciones del cursor (Movimiento, click...), Acciones links, drag...

    BG.init(CMS_COLORS); // Control de paletas y color de fondo de pantallas. Automatico si añadimos un data-palette='loquesea' en el div con data-page
    CMP.init(); // Checkea y saca el aviso de cookies

    LoaderController.add(new PagesLoader()); // Carga/Precarga de paginas HTML
    // LoaderController.add(new MediaLoader()); // Carga/Precarga de imgs
    LoaderController.update = progress => Preloader.update(progress);
    LoaderController.onComplete = () => Main.setup();

    this.doCuchilloInfo();

    // LOOP
    if (isDebug) {
      gsap.ticker.add(() => { Main.loopDebug(); });
    } else {
      gsap.ticker.add(() => { Main.loop(); });
    }

    Preloader.init();
    LoaderController.init();
  }

  static setup() {
    this.setupEvents();
    // INIT PAGE
    ControllerPage.init(Wrap.mainholder);
  }

  static setupEvents() {
    // EventDispatcher.addEventListener(Page.ON_SHOW, () => {
    //   Loading.stop();
    // });
    EventDispatcher.addEventListener(Page.ON_HIDE, () => {
      if (isSmartphone) Sidemenu.hide();
    });
    // EventDispatcher.addEventListener(Page.ON_HIDE_END, () => {
    //   Loading.start();
    // });

    // EventDispatcher.addEventListener(Win.ON_HIDE, () => { Scroll.setEnabled(true); });
    // EventDispatcher.addEventListener(Win.ON_SHOW, () => { Scroll.setEnabled(false); });
    EventDispatcher.addEventListener(Sidemenu.ON_HIDE, () => { Scroll.setEnabled(true); });
    EventDispatcher.addEventListener(Sidemenu.ON_SHOW, () => {
      Scroll.setEnabled(false);
      // ControllerWindow.hideAll();
    });
  }

  static resize() {
    Sidemenu.resize();
    BG.resize();
    ControllerPage.resize();

    if (isTouch) GetBy.id('Main').style.height = `${Metrics.HEIGHT}px`;
  }

  static loop() {
    if (Scroll.isScrolling) Scroll.loop();
    Sidemenu.loop();
    ControllerPage.loop();
    BG.loop();
    Header.loop();
  }

  static loopDebug() {
    Statics.begin();
    this.loop();
    Statics.end();
  }

  static doCuchilloInfo() {
    console.log('%cby Cuchillo', 'background: #000; color: #bada55; padding:25px 100px;');
    console.log('⟶ http://somoscuchillo.com');
    console.log('⟶ https://www.instagram.com/_cuchillo');
    console.log('⟶ https://twitter.com/somoscuchillo');
    console.log('⟶ https://twitter.com/mr__corrales');
    console.log('');
    console.log('Gsap by Greenshock');
    console.log('⟶ https://greensock.com');
    console.log('');
    console.log('Font: Fraktion Mono by Pangram Pangram');
    console.log('⟶ https://pangrampangram.com/products/fraktion');
    console.log('');
    console.log('SVGOMG');
    console.log('⟶ https://jakearchibald.github.io/svgomg/');
    console.log('');
    console.log('Favicon Generator');
    console.log('⟶ https://realfavicongenerator.net');
  }
}

if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") {
  Main.init();
} else {
  document.addEventListener('DOMContentLoaded', Main.init);
}
